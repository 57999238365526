var signalR = require('@microsoft/signalr');

function getFortusSignalRHubConnectionInfo(url, callback) {
    $.ajax({
        type: "POST",
        url: url,
        contentType: "application/json",
        success: function (connectionInfo) {
            var connectionInfoAsJson = JSON.parse(connectionInfo);
            callback(connectionInfoAsJson);
        }
    });
}

window.InitBankIdQrCodeHandler = function (bankIdQrCodeUpdateUrl) {
    getFortusSignalRHubConnectionInfo(bankIdQrCodeUpdateUrl, function (info) {
        // make compatible with old and new SignalRConnectionInfo
        info.accessToken = info.AccessToken || info.accessKey; // pay attention to the case
        info.url = info.Url || info.endpoint; // pay attention to the case
        const options = {
            accessTokenFactory: () => info.accessToken
        };

        window.BankIdQrCodeHandler = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Error)
            .build();

        window.BankIdQrCodeHandler.on('updateBankIdQrCodeSvg', (bankIdQrCodeSvg) => {
            document.getElementById("bank-id-qr-code-container").innerHTML = bankIdQrCodeSvg;
        });

        window.BankIdQrCodeHandler.start().catch(console.error);
    });
}
